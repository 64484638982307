export default {
  data() {
    return {
      listData: [{
        img: require("../../assets/index_details1.jpg"),
        title: "国家数字文件"
      }, {
        img: require("../../assets/index_details2.jpg"),
        title: "数字时代指南"
      }, {
        img: require("../../assets/index_details3.jpg"),
        title: "数字贡献机制"
      }, {
        img: require("../../assets/index_details4.jpg"),
        title: "团队分红说明"
      }, {
        img: require("../../assets/index_details5.jpg"),
        title: "数字时代"
      }]
    };
  },
  computed: {
    pageVale() {
      return this.listData[this.$route.query.id];
    }
  }
};